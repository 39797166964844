import "bootstrap/dist/css/bootstrap.min.css"

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import UserPortal from './pages/userPortal';
import StoreList from './pages/storeList';
// import PreApproval from './pages/preApproval';

// main app routes
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<UserPortal />} />
        <Route path="directorio/" element={<StoreList />} />
        {/* <Route path="/registro" element={<PreApproval />} /> */}
      </Routes>
    </Router>
  );
};

export default App;