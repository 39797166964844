
import React, { useState, useEffect } from "react";
import image from '../images/Krece-logo_white.png';
import { AppBar, Toolbar, Typography, Container, Button, TextField, makeStyles, MenuItem } from '@material-ui/core';
import { FaChevronDown, FaChevronUp, FaExclamationTriangle } from 'react-icons/fa'; // For icons
import 'firebase/compat/firestore';
import firestore from '../firestore'
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack, ArrowForward, Delete } from '@material-ui/icons';
import { BeatLoader } from 'react-spinners';
import auth from '../auth';



const UserPortal = () => {
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(number);
    };
    const classes = useStyles();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [cedula, setCedula] = useState('');
    const [loginError, setLoginError] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [payments, setPayments] = useState({});
    const [showPayments, setShowPayments] = useState({});
    const [amountLeftToPay, setAmountLeftToPay] = useState(0);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
    const [paymentDocId, setPaymentDocId] = useState('');
    const [amountToPayDollars, setAmountToPayDollars] = useState('');
    const [paymentDocRef, setPaymentDocRef] = useState('');
    const [exchangeRate, setExchangeRate] = useState('');
    const [payMethod, setPayMethod] = useState('');
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [clientName, setClientName] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberPm, setPhoneNumberPm] = useState('');
    const [bank, setBank] = useState('');
    const [emailForAuthCode, setEmailForAuthCode] = useState('');
    const [formError, setFormError] = useState('');
    const [showTokenSent, setShowTokenSent] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const [pagoMovilData, setPagoMovilData] = useState({});
    const [transferData, setTransferData] = useState({});
    const [countrySettings, setCountrySettings] = useState({});
    const [country, setCountry] = useState(null);

    const bankCodesList = ['0102 - Banco de Venezuela (BDV)', '0104 - Banco Venezolano de Crédito (BVC)', '0105 - Banco Mercantil', '0108 - Banco Provincial (BBVA)', '0114 - Bancaribe', '0115 - Banco Exterior', '0128 - Banco Caroní', '0134 - Banesco Banco Universal', '0137 - Sofitasa', '0138 - Banco Plaza', '0146 - Bangente', '0151 - Banco Fondo Común (BFC)', '0156 - 100% Banco', '0157 - Del Sur Banco Universal', '0163 - Banco del Tesoro', '0166 - Banco Agrícola de Venezuela', '0168 - Bancrecer', '0169 - Mi Banco, Banco Microfinanciero C.A', '0171 - Banco Activo', '0172 - Bancamiga', '0174 - Banplus', '0175 - Banco Bicentenario del Pueblo', '0177 - Banco de la Fuerza Armada Nacional Bolivariana (BANFANB)', '0191 - Banco Nacional de Crédito (BNC)']


    useEffect(() => {
        firestore.collection('AppSetting').doc('AppSettings')
            .get().then((doc) => {
                const data = doc.data();
                auth.signInWithEmailAndPassword('appreg@krece.app', data.firebase_master_token_id)
                setIsLoading(false);
            }).catch((err) => {
                console.log(err.message)
                setIsLoading(false);
            })
        if (country) {
            retrievePhoneNumber();
        }
    }, [country])

    const goToPage = (x) => {
        setInfoMessage('')
        setLoginError('')
        setPage(x);
    }

    function replaceFirstCharWith58(originalString) {
        if (typeof originalString !== 'string' || originalString.length === 0) {
            // Handle invalid input or empty string
            return originalString;
        }

        const modifiedString = '58' + originalString.substring(1);
        return modifiedString;
    }

    const backToWelcomePage = () => {
        window.location.reload();
    };

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 30000);

            const response = await fetch(`https://api.krece.app/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await auth.currentUser.getIdToken(true)}`,
                    'x-master-store-email': null,
                    'x-user-email': 'appreg@krece.app'
                },
                body: JSON.stringify({ ...data, country: country }),
                signal: controller.signal,
            });
            clearTimeout(timeoutId);
            // Handle the response data
            if (response.status === 401) {
                // Handle 401 Unauthorized, refresh token, and retry
                const tokenData = await requestToken();
                await setToken(`Token ${tokenData.token}`);
                setTimeout(() => sendPostRequest(data, endpoint, `Token ${tokenData.token}`), 2000);
                // return await sendPostRequest(data, endpoint, `Token ${tokenData.token}`);
            }

            return await response.json();
        } catch (error) {
            return null;
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setInfoMessage('')
        setLoginError('')
        try {
            const cedulaData = {
                identification_number: cedula
            }
            const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token) //status, success, data 
            if (response?.success) {
                if (response.data.is_user_registered && !response.data.can_be_financed) {
                    await getPayments();
                } else if (response.data.is_user_registered && response.data.can_be_financed) {
                    await setLoginError('Este cliente no tiene pagos pendientes con Krece.');
                    setIsLoading(false);
                } else {
                    await setLoginError('No encontramos ningun cliente asociado a esta cédula.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setLoginError(response.status);
                setIsLoading(false);
            } else {
                setLoginError('Estamos teniendo problemas para conectarnos. ');
                setIsLoading(false);
            }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos. ');
            setIsLoading(false);
        }
    }

    const getPayments = async () => {
        const paymentsCollection = firestore.collection('Payments');

        // Get a reference to the Firestore collection for users
        const usersCollection = firestore.collection('Users');

        // Query the collection based on the "user" field reference
        try {
            const queryUser = usersCollection
                .where('identification_number', '==', cedula)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setEmail(doc.id);
                    setClientName(doc.data()['first_name'] + " " + doc.data()['last_name'])
                    const showPayments2 = {};
                    let isDevicePaymentsShown = true;
                    const query = paymentsCollection
                        .where('user', '==', usersCollection.doc(doc.id))
                        .where('is_active', '==', true)
                        .orderBy('dueDate', 'asc')
                    const unsubscribe = query.onSnapshot(async (querySnapshot) => {
                        const paymentDetails = {};
                        let amountLeft = 0;

                        const deviceIds = [];
                        const showPayments2 = {};
                        let isDevicePaymentsShown = true;

                        try {
                            const promises = querySnapshot.docs.map(async (doc) => {
                                const data = doc.data();
                                amountLeft += data.actualAmount - data.amountPaid;

                                if (paymentDetails.hasOwnProperty(data.device.id)) {
                                    paymentDetails[data.device.id].payments.push({ ...data, id: doc.id });
                                } else {
                                    paymentDetails[data.device.id] = {
                                        payments: [{ ...data, id: doc.id }],
                                        name: data.device.id,
                                        date: '',
                                        isDelayed: false,
                                    };
                                    deviceIds.push(data.device.id);
                                    if (isDevicePaymentsShown) {
                                        showPayments2[data.device.id] = true;
                                        isDevicePaymentsShown = false;
                                    } else {
                                        showPayments2[data.device.id] = false;
                                    }
                                }

                                const dueDate = data.dueDate.toDate();
                                dueDate.setDate(dueDate.getDate() + 1);
                                if (!data.paid && dueDate < new Date()) {
                                    paymentDetails[data.device.id].isDelayed = true;
                                }
                            });

                            await Promise.all(promises);
                            await fetchDeviceDetails(deviceIds, paymentDetails);

                            setPayments(paymentDetails);
                            setShowPayments(showPayments2);
                            setAmountLeftToPay(amountLeft);
                            setIsLoading(false);
                            goToPage(2);
                        } catch (err) {
                            console.error('Error processing payments:', err.message);
                            setLoginError('Ocurrió un problema, intente de nuevo más tarde.');
                            setIsLoading(false);
                        }
                    });

                });
            }).catch((error) => {
                setIsLoading(false);
                alert('Error: ' + error.message)
            })
        } catch (error) {
            setIsLoading(false);
            alert('Error: ' + error.message)
        }
    }

    const fetchDeviceDetails = async (deviceIds, paymentDetails) => {
        try {
            const promises = deviceIds.map(async (id) => {
                const deviceDoc = await firestore.collection('Devices').doc(id).get();
                const deviceData = deviceDoc.data();
                paymentDetails[id].name = deviceData.device_commercial_name
                    ? deviceData.device_commercial_name
                    : `${deviceData.device_manufacturer} ${deviceData.device_model}`;
                paymentDetails[id].date = formatDate(deviceData.date_bought);
            });
            await Promise.all(promises);
        } catch (err) {
            setLoginError("Ocurrio un error: " + err.message)
            setIsLoading(false)
        }
    };

    const showPaymentScreen = (paymentId) => {
        setIsLoading(true);

        if (country === 'rd') {
            window.open(countrySettings.payment_instr_url, '_blank', 'noopener,noreferrer')
            setIsLoading(true);
            return;
        }
        firestore.collection('Payments').doc(paymentId).get().then(async (doc) => {
            // Access the document data
            const amountToPay = doc.data()['actualAmount'] - doc.data()['amountPaid'];
            setPaymentDocId(doc.id);
            setAmountToPayDollars(amountToPay);
            setPaymentDocRef(doc);
            goToPage(3);
            let paymentReceiver = 'default';
            if (doc.data().store_financed === true) {
                paymentReceiver = doc.data().related_store
            }
            firestore.collection("StorePaymentMethods").doc(paymentReceiver)
                .onSnapshot(async (doc) => {
                    if (doc.exists) {
                        setPagoMovilData(doc.data()['pagomovil']);
                        setTransferData(doc.data()['transferBs']);
                    }
                    setIsLoading(false);
                })
            setIsLoading(false);

        });
        (async () => {
            try {
                const docRef = await firestore.collection('DollarPrice').doc('dollarPrice');
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        setExchangeRate(doc.data()['bcv'])
                    } else {
                        alert('Ocurrió un problema, intente de nuevo más tarde.')
                        setIsLoading(false);
                    }
                })
                // const response = await getDollarPrices()
                // const rate = response[0].dollar
                // setExchangeRate(rate)
            } catch (error) {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            }
        })();
    }

    const isNumber = async (value) => {
        return /^[+-]?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?(,\d+)?$/.test(value);
    }

    function convertToDotDecimal(str) {
        if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
            return str.replace(/,/g, ".");
        } else {
            return str;
        }
    }

    const handlePaymentSubmit = async () => {
        if (!confirmationNumber || !amountPaid || !paymentDate || !payMethod) {
            alert('Llene todos los datos para continuar');
            return;
        } else {
            setIsLoading(true);
            try {
                const response = await sendPaymentConfirmation()
                if (response?.success) {
                    setIsLoading(false)
                    alert("Su pago fue confirmado exitosamente")
                } else if (response?.success == false) {
                    setIsLoading(false)
                    alert("Error: " + response?.status)
                } else {
                    alert("Ocurrió un problema intente más tarde")
                    setIsLoading(false)
                }

            } catch (err) {
                alert(err.message)
                setIsLoading(false)
            }
        }
    };

    function formatForAPI(date) {
        // Format the date as yyyy/mm/dd
        const formattedDate = date.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York', // GMT-4 timezone (Eastern Time)
        }).replace(/\//g, '-'); // Replace slashes with dashes

        return formattedDate;
    }

    const sendPaymentConfirmation = async () => { //todo: check if amountPaid is numerical before sending the call
        let data
        if (payMethod === "transfer") {
            data = {
                payment_object_id: paymentDocId,
                reference: confirmationNumber,
                amount: convertToDotDecimal(amountPaid),
                payment_type: "TRANSFER",
                date: formatForAPI(paymentDate),
                api_type: 'banesco'
            }
        } else {
            data = {
                payment_object_id: paymentDocId,
                reference: confirmationNumber,
                amount: convertToDotDecimal(amountPaid),
                payment_type: "P2C",
                phone_num: replaceFirstCharWith58(phoneNumberPm),
                bank_code: bank.split(' - ')[0],
                date: formatForAPI(paymentDate),
                api_type: 'banesco'
            }
        }
        console.log(data)
        try {
            const tokenData = await requestToken()
            const response = await fetch('https://api.krece.app/process_payment/', { //agregar un catch aqui
                method: 'POST',
                // mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Token " + tokenData.token
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    }

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
        const month = date.toLocaleDateString('es-ES', { month: 'short' }); // Get the short month name
        const day = date.getDate(); // Get the day of the 
        return `${day} de ${month.charAt(0).toUpperCase() + month.slice(1)}`;
    };

    const checkCedula = async () => {
        setLoginError('');
        if (!cedula) {
            setLoginError('Llene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const q = await firestore.collection('Users').where('identification_number', '==', cedula).get();
            q.docs.map(async doc => {
                if (doc.exists) {
                    const countryDoc = await firestore.collection('AppSetting').doc('CountrySettings').get();
                    const countryData = countryDoc.data();
                    setCountrySettings(countryData[doc.data()['country']]);
                    setCountry(doc.data()['country']);
                } else {
                    setLoginError('No existe ningun cliente asociado a esta cédula.');
                    setIsLoading(false);
                }
            });


            // const cedulaData = {
            //     identification_number: cedula
            // }
            // const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token) //status, success, data 
            // console.log(response)
            // if (response?.success) {
            //     if (response.data.is_user_registered) {
            //         await retrievePhoneNumber();
            //     } else {
            //         setLoginError('No encontramos a cliente asociado a esta cédula. Por favor contactar a soporte técnico.');
            //         setIsLoading(false);
            //     }
            // } else if (response?.success === false) {
            //     setLoginError(response.status);
            //     setIsLoading(false);
            // } else {
            //     setLoginError('Estamos teniendo problemas para conectarnos.');
            //     setIsLoading(false);
            // }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const retrievePhoneNumber = async () => {
        try {
            const usersCollection = firestore.collection('Users');
            const queryUser = usersCollection
                .where('identification_number', '==', cedula)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                querySnapshot.forEach(async (doc) => {
                    setPhoneNumber(doc.data()['phone_number']);
                    setEmailForAuthCode(doc.id);
                    sendAuthCodeToUser(doc.data()['phone_number']);
                })
            }).catch((err) => {
                setLoginError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            })
        } catch (err) {
            setLoginError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const sendAuthCodeToUser = async (phoneNumberForAuthCode) => {
        try {
            const codeData = {
                phone_number: phoneNumberForAuthCode,
                code_type: 'sms'
            }
            const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token) //status, success, data
            if (response?.success) {
                setShowTokenSent(true)
                setIsLoading(false);
            } else if (response?.success === false) {
                setLoginError(response.status);
                setIsLoading(false);
            } else {
                setLoginError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const confirmAuthCodeFromUser = async () => {
        setLoginError('');
        if (!authCode) {
            setLoginError('Llene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const codeData = {
                confirmation_code: authCode
            }
            const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token) //status, success, data
            if (response?.success) {
                await getPayments();
            } else if (response?.success === false) {
                setLoginError(response.status);
                setIsLoading(false);
            } else {
                setLoginError('Estamos teniendo problemas para conectarnos.');
                setIsLoading(false);
            }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }



    const GetPaymentObjects = (paymentList) => {
        var isFuturePayment = true
        var checkedByInternal = false
        var isFirst = true
        const paym = []
        paymentList.forEach((item, index) => {
            if (item.paid && item.checked_by_internal_team) {
                checkedByInternal = true
                var PayObj = <span className={classes.textSmall3}>PAGADO</span>
            } else if (item.paid && !item.checked_by_internal_team) {
                checkedByInternal = false
                var PayObj = <span className={classes.textSmall4}>Por Confirmar</span>
            } else {
                <div><span className={classes.textSmall4}></span></div>
            }
            if (isFuturePayment && !item.paid && checkedByInternal) {
                PayObj = <button onClick={() => showPaymentScreen(item.id)} className={classes.button}>
                    <span className={classes.text}>Pagar</span>
                </button>
                isFuturePayment = false
                checkedByInternal = true
            }
            // checkedByInternal = false
            if (isFirst && !item.paid) {
                PayObj = <button onClick={() => showPaymentScreen(item.id)} className={classes.button}>
                    <span className={classes.text}>Pagar</span>
                </button>
            }
            isFirst = false
            paym.push(<div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                <span className={classes.textSmall2}>{formatDate(item.dueDate)}</span>
                {PayObj}
                <span className={classes.textSmall2}>{countrySettings.currency_code + (item.actualAmount - item.amountPaid).toFixed(2)}</span>
            </div>)
        })
        return paym
    }

    const backToHome = () => {
        window.location.reload()
    }

    if (isLoading) {
        return (
            <div style={{ backgroundColor: '#2A2E32', height: '100%', minHeight: '100vh', paddingTop: 100, paddingBottom: 100 }}>
                <Container maxWidth="sm" style={{
                    marginTop: 100,
                    marginHorizontal: 10,
                    paddingBottom: 35,
                    marginBottom: 30,
                    borderRadius: '8px',
                    paddingRight: 50,
                    paddingLeft: 50,
                    boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                    paddingTop: '30px',
                    backgroundColor: '#1C1C1E',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        padding: 20, justifyContent: 'center', marginBottom: 30, display: 'flex'
                    }}>
                        <img style={{ alignSelf: 'center', width: 250 }} src={image} alt="Krece logo" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                        <BeatLoader color="#45C4D6" />
                    </div>
                </Container >
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: '#2A2E32', height: '100%', minHeight: '100vh', paddingTop: 100, paddingBottom: 100 }}>
            <Container maxWidth="sm" style={{
                marginTop: 100,
                marginHorizontal: 10,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: '30px',
                backgroundColor: '#1C1C1E',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div style={{
                    padding: 20, justifyContent: 'center', marginBottom: 30, display: 'flex'
                }}>
                    <img style={{ alignSelf: 'center', width: 250 }} src={image} alt="Krece logo" />
                </div>
                {page === 1 && (
                    <div>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Iniciar Sesión
                        </Typography>
                        {
                            !showTokenSent ? <form>
                                <TextField
                                    label="Cédula de identidad"
                                    type="cedula"
                                    name="cedula"
                                    value={cedula}
                                    onChange={(e) => setCedula(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField>
                                <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={checkCedula} fullWidth>
                                    Iniciar Sesión
                                </Button>
                                {formError && <p className={classes.formError}>{formError}</p>}
                            </form> : <form>
                                <p style={{ textAlign: 'center', color: "white", fontSize: 16 }}>{"Le enviamos un código de verificación por SMS al cliente al ******" + phoneNumber.substring(phoneNumber.length - 4)}</p>
                                <TextField
                                    label="Código de verificación"
                                    name="authCode"
                                    value={authCode}
                                    onChange={(e) => setAuthCode(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField>
                                <Button type="submit" variant="contained" className={classes.welcomeButtons} onClick={confirmAuthCodeFromUser} fullWidth>
                                    Siguiente
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<ArrowBack />}
                                    onClick={() => setShowTokenSent(false)}
                                    className={classes.welcomeButtons}
                                >
                                    Atrás
                                </Button>
                                {formError && <p className={classes.formError}>{formError}</p>}
                            </form>
                        }

                    </div>
                )}
                {page === 2 && (
                    <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1, padding: 20 }}>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Mis Cuotas
                        </Typography>
                        <Typography variant="h5" align="center" style={{ color: 'white', fontSize: 14, marginBottom: 20 }}>
                            {clientName}
                        </Typography>
                        {Object.keys(payments).length > 0 ? (
                            Object.keys(payments).map((item, idx) => (
                                <div key={item} className={classes.card}>
                                    <button
                                        style={{ display: 'flex', justifyContent: 'space-between', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%' }}
                                        onClick={() => setShowPayments({ ...showPayments, [item]: !showPayments[item] })}
                                    >
                                        <div style={{ alignItems: 'left', justifyContent: 'left' }}>
                                            <div style={{ flex: 1, justifyContent: 'left', marginTop: 10 }}>
                                                <span style={{ color: '#ffffff', fontSize: 18 }} weight='bold'>{payments[item].name}</span>
                                            </div>
                                            <div style={{ flex: 1, justifyContent: 'left', marginBottom: 10 }}>
                                                <span style={{ fontSize: 12, color: 'silver', paddingBottom: 10, marginRight: 5 }}>{'Comprado el ' + payments[item].date}</span>
                                            </div>
                                        </div>

                                        {payments[item].isDelayed && (
                                            <div style={{ justifyContent: 'center', marginTop: 10 }}>
                                                <FaExclamationTriangle color="red" size={20} />
                                                <span style={{ color: 'red', fontSize: 10 }}>Cuota vencida</span>
                                            </div>
                                        )}

                                        <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                            {showPayments[item] ? (
                                                <FaChevronUp color="#FFFFFF80" size={20} />
                                            ) : (
                                                <FaChevronDown color="#FFFFFF80" size={20} />
                                            )}
                                        </div>
                                    </button>

                                    {showPayments[item] && (
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
                                                <div ><span className={classes.textSmall5} weight='bold'>Vence</span></div>
                                                <div ><span className={classes.textSmall5} weight='bold'>Estado</span></div>
                                                <div ><span className={classes.textSmall5} weight='bold'>Debe</span></div>
                                            </div>
                                            {/* Assuming GetPaymentObjects is a function that renders sub-elements */}
                                            {GetPaymentObjects(payments[item].payments)}
                                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 15, paddingTop: 15 }}>
                                                <div>
                                                    <span className={classes.textSmall} weight='bold'>Saldo restante</span>
                                                </div>
                                                <div>
                                                    <span className={classes.textSmall} weight='bold'>{countrySettings.currency_code + formatNumber(amountLeftToPay.toFixed(2))}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className={classes.card}>
                                <span className={classes.textSmall2}>{`No tienes cuotas pendientes. Dirígete a una de nuestras tiendas aliadas para obtener tu equipo a cuotas HOY!`}</span>
                            </div>
                        )}
                        <Button
                            variant="contained"
                            onClick={backToWelcomePage}
                            className={classes.welcomeButtons}
                        >
                            Salir
                        </Button>
                    </div>
                )}
                {page === 3 && (
                    <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Pago de cuota
                        </Typography>
                        <div style={{ padding: 20 }}>
                            <Typography className={classes.label}>Monto a pagar</Typography>
                            <Typography className={classes.label3}>{countrySettings.currency_code + (amountToPayDollars).toFixed(2) + " / " + " Bs. " + (amountToPayDollars * exchangeRate).toFixed(2) + " (A tasa BCV)"}</Typography>
                            <TextField
                                select
                                label="Método de pago"
                                name="payMethod"
                                value={payMethod}
                                onChange={(e) => setPayMethod(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                error={loginError !== ''}
                                helperText={loginError}
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            >
                                <MenuItem value="pagomovil">Pago Movil</MenuItem>
                                <MenuItem value="transfer">Transferencia</MenuItem>
                            </TextField>
                            <div>
                                <TextField
                                    label="Número de referencia"
                                    type="confirmationNumber"
                                    name="confirmationNumber"
                                    value={confirmationNumber}
                                    onChange={(e) => setConfirmationNumber(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField>
                            </div>
                            <div>
                                <TextField
                                    label="Monto del pago (en Bolivares)"
                                    type="amountPaid"
                                    name="amountPaid"
                                    value={amountPaid}
                                    onChange={(e) => setAmountPaid(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField>
                            </div>
                            <TextField
                                label="Fecha del pago"
                                name="paymentDate"
                                type="date"
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                error={loginError !== ''}
                                helperText={loginError}
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                    shrink: true
                                }}></TextField>
                            {payMethod === 'pagomovil' && <div>
                                <TextField
                                    label="Teléfono de origen (Formato Ejemplo: 04141234567)"
                                    type="phoneNumberPm"
                                    name="phoneNumberPm"
                                    value={phoneNumberPm}
                                    onChange={(e) => setPhoneNumberPm(e.target.value.trim())}
                                    // variant="outlined"
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}></TextField>

                                <TextField
                                    select
                                    label="Banco de origen del Pagomovil"
                                    name="bank"
                                    value={bank}
                                    onChange={(e) => setBank(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    error={loginError !== ''}
                                    helperText={loginError}
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                >
                                    {bankCodesList.map((item, index) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </TextField>
                            </div>}
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handlePaymentSubmit} gutterBottom>
                                Enviar
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                startIcon={<ArrowBack />}
                                onClick={() => goToPage(2)}
                                style={{ marginTop: '10px' }}
                            >
                                Atrás
                            </Button>
                            <Typography variant="h5" className={classes.title}>
                                Detalles de las cuentas a pagar
                            </Typography>
                            <Typography className={classes.label}>
                                PagoMovil <br />
                                {'Banco: ' + pagoMovilData.bank}<br />
                                {'Rif: ' + pagoMovilData.rif}<br />
                                {'#Teléfono: ' + pagoMovilData.phoneNumber}<br />
                                <br />
                                Transferencia<br />
                                {'Cuenta: ' + transferData.account}<br />
                                {'Rif: ' + transferData.rif}<br />
                                {'Banco: ' + transferData.bank}<br />
                                {'Tipo: ' + transferData.type}<br />
                                <br />
                            </Typography>
                        </div>
                    </div>
                )}
                {page === 4 && (
                    <div>
                        <Typography variant="h4" align="center" className={classes.title}>
                            Gracias por completar su pago
                        </Typography>
                        <Typography className={classes.label}>Hemos recibido la información de pago. Nuestro equipo confirmará su pago en las próximas 24 horas y lo podrá ver reflejado en la app</Typography>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(2)}
                            style={{ marginTop: '10px' }}
                        >
                            Volver
                        </Button>
                    </div>
                )}
            </Container>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: '#2A2E32',
        borderRadius: 25,
        borderBottomWidth: 0,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingVertical: 20,
        justifyContent: 'center',
        elevation: 5
    },
    paymentItem2: {
        flex: 1,
        display: 'row',
        marginBottom: 15,
        justifyContent: 'center',
        alignItems: 'center'
    },
    approvalMsg: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#000000',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#000000',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        height: 700,
    },
    infoMessage: {
        color: "white"
    },
    title: {
        color: '#ffffff',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    textSmall: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    textSmall2: {
        fontSize: 14,
        color: 'white',
    },
    textSmall3: {
        fontSize: 14,
        color: '#45C4D6',
        fontWeight: 'bold'
    },
    textSmall4: {
        fontSize: 14,
        color: 'white',
        fontWeight: 'bold'
    },
    textSmall5: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 14
    },
    paymentItem: {
        // width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        diplay: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    button: {
        width: 150,
        height: 40,
        borderRadius: 50,
        backgroundColor: '#45C4D6'
    },
    label: {
        fontSize: 20,
        marginBottom: 8,
        color: 'white'
    },
    label2: {
        fontSize: 20,
        marginBottom: 8,
        marginTop: 20,
        color: 'white'
    },
    label3: {
        fontSize: 16,
        marginBottom: 8,
        color: '#45C4D6',
        marginLeft: 20
    },
}));

export default UserPortal;
